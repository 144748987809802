.Referr {
    margin-top: 25px;
}
.Referr > .flex-row {
    justify-content: space-between;
    padding: 0 15px;
}
.Referr .bg-txt {
    margin: 5px 0 1rem 0;
}

#staked,
#referral {
    padding: 16px;
    border-radius: 8px;
}

#staked {
    width: 32%;
    background-color: var(--purple);
    color: white;
}
#staked .cta-fw {
    --color: rgba(133, 133, 133, 1);
    --d-color: rgba(113, 113, 113, 1);
    --s-color: rgba(113, 113, 113, .8);
}

#referral {
    width: 70%;
    margin-left: 30px;
    background-color: rgba(133, 133, 133, 0.1);
    border: 1px solid var(--blue);
}
#referral .bg-txt {
    color: var(--blue);
}
#referral > .flex-col > .flex-row {
    padding: 0 15px;
}

.input {
    border-radius: 5px;
    background-color: rgba(133, 133, 133, 0.8);
    color: white;
    padding: .375rem .75rem;
    width: 100%;
    margin-right: 15px;
}

@media (max-width: 991px) {
    .Referr > .flex-row {
        flex-wrap: wrap;
    }
    .Referr #staked,
    .Referr #referral {
        width: 100%;
    }
    .Referr #referral {
        margin: 25px auto 0 auto;
    }
}
@media (max-width: 600px) {
    #referral > .flex-col > .flex-row:nth-child(3) {
        flex-wrap: wrap;
    }
    #referral > .flex-col > .flex-row:nth-child(3) > .flex-col {
        width: 50% !important;
    }
    #referral > .flex-col > .flex-row:nth-child(3) > .flex-col:nth-child(3) {
        width: 100% !important;
    }
}