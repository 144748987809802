.Header {
	margin-bottom: 25px;
}
.Header-header {
	padding: 0 15px;
}
.Header #logo img {
	width: 50px;
	height: 50px;
}
.Header #logo .bg-txt {
	margin: auto 10px;
	font-size: 28px;
}
.Header #logo .bg-txt span {
	color: var(--blue);
}
.Header #text img {
	width: 200px;
	height: 50px;
}
.Header #text .bg-txt {
	margin: auto 10px;
	font-size: 28px;
}
.Header #text .bg-txt span {
	color: var(--blue);
}
.Header #stake-price span {
	color: var(--purple);
}
.Header .cta {
	margin-left: 5px;
}
#wallet {
	cursor: pointer;
}
