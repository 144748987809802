.App {
	margin-bottom: 25px;
}
.App-header {
	padding: 0 15px;
}
.App #logo img {
	width: 50px;
	height: 50px;
}
.App #logo .bg-txt {
	margin: auto 10px;
	font-size: 28px;
}
.App #logo .bg-txt span {
	color: var(--blue);
}
.App #stake-price span {
	color: var(--purple);
}
.App .cta {
	margin-left: 5px;
}
#wallet {
	cursor: pointer;
}
