:root {
	--purple: rgb(118, 69, 217);
	--d-purple: rgb(98, 49, 197);
	--s-purple: rgba(98, 49, 197, 0.5);
	--blue: rgb(31, 199, 212);
	--d-blue: rgb(11, 189, 192);
	--s-blue: rgba(11, 189, 192, 0.5);
}

html *::selection {
	background-color: var(--blue);
	color: white;
}

::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	background: rgb(239, 244, 245);
}
::-webkit-scrollbar-thumb {
	background: rgba(133, 133, 133, 0.1);
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: white;

	max-width: 100vw;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	margin: auto;
}

header {
	border-bottom: 2px solid rgba(133, 133, 133, 0.1);
}
header > .container {
	height: 60px;
	display: flex;
	flex-flow: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
}

#wallet {
	font-weight: 900;
	color: var(--blue);
	background-color: rgb(239, 244, 245);
	max-width: 200px;
	padding: 5px 10px;
	border-radius: 16px;
	overflow: hidden;
}

.container {
	max-width: 1140px;
	padding: 0 15px;
	margin: auto;
}

/* CTA */
.cta,
.cta-fw {
	--color: var(--blue);
	--d-color: var(--d-blue);
	--s-color: var(--s-blue);

	border-radius: 5px;
	background-color: var(--color);
	color: rgb(239, 244, 245);
	cursor: pointer;
}
.cta:hover,
.cta-fw:hover {
	background-color: var(--d-color);
}
.cta:active,
.cta-fw:active {
	box-shadow: 0 0 0 0.2rem var(--s-color);
}

.cta {
	padding: 5px 15px;
	font-weight: 700;
}
.cta-fw {
	padding: 10px 0;
	width: 100%;
	display: block;

	text-align: center;
	font-size: 18px;
	font-weight: 600;

	transition: box-shadow 0.2s;
}

/* Flex */
.d-flex {
	display: flex;
}
.flex-row {
	display: flex;
	flex-flow: row;
	flex-wrap: nowrap;

	margin-right: -15px;
	margin-left: -15px;
}
.flex-col {
	display: flex;
	flex-flow: column;
	flex-wrap: nowrap;

	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.j-content-center {
	justify-content: center;
}

/* Padding */
.p-1 {
	padding: 1rem;
}
.m-0 {
	margin: 0;
}

/* Tamaño */
.w-50 {
	width: 50%;
}

/* Texto */
.sm-txt {
	font-size: 12px;
	margin: 0;
}
.bg-txt {
	font-size: 45px;
	font-weight: 700;
	line-height: 1;
	color: var(--color);

	margin-bottom: 25px;
}
.italic {
	font-style: italic;
}

@media (max-width: 911px) {
	#header-buttons {
		display: none;
	}
	.Header-header div[style='width: 25%;'] {
		display: none;
	}
	#wallet {
		margin: 0 auto 0 20px;
	}
}
