.Card {
    --color: var(--blue);

    background-color: rgb(239, 244, 245);
    border-radius: 8px;
    padding: 16px 16px 16px 16px;
    border: 1px solid var(--color);

    position: relative;
    overflow: hidden;
}
.Card p {
    text-align: center;
    margin-top: 0;
}
.Card .plan {
    color: white;
    background-color: var(--color);
    padding: 20px 8px 4px 20px;
    border-radius: 6px;

    position: absolute;
    top: -18px;
    left: -12px;
}

.Card .plan p {
    margin: 0;
    font-size: 12px;
}

@media (max-width: 1100px) {
    .Card .flex-row:nth-child(2) {
        flex-wrap: wrap;
    }

    .Card .flex-col.w-50 {
        width: 100%;
    }
}
@media (max-width: 911px) {
    .Card .flex-row:nth-child(2) {
        flex-wrap: nowrap;
    }

    .Card .flex-col.w-50 {
        width: 50%;
    }
}
/* @media (max-width: 600px) {
    .Card .flex-row {
        flex-wrap: wrap !important;
    }

    .Card .flex-col.w-50 {
        width: 100%;
    }
} */